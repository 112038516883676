import React from "react";
import * as Icon from "react-feather";
import { getUserLogged } from "./config"

let navigationConfig = []
if(getUserLogged()){
  if(getUserLogged().userrole){
    if(getUserLogged().userrole === 100 || getUserLogged().userrole === "100" ){
      navigationConfig = [
        {
          id: "ujian",
          title: "Ujian",
          icon: <Icon.BookOpen color={"#1a1c20"} size={20} />,
          type: "collapse",
          children: [
            {
              id: "daftarSertifikat",
              title: "Daftar Sertifikat",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/sertifikat",
            },
            {
              id: "jadwalUjian",
              title: "Jadwal Ujian",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/ujian/sesi",
            },
            {
              id: "pesertaUjian",
              title: "Peserta Ujian",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/pesertaujian",
            }
          ],
        },
        {
          id: "monitoring",
          title: "Monitoring",
          icon: <Icon.Calendar color={"#1a1c20"} size={20} />,
          type: "collapse",
          children: [
            {
              id: "monitoringUjian",
              title: "Monitoring Ujian",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/monitoring/realtime",
            },
            {
              id: "verifikasiPelanggaran",
              title: "Verifikasi Pelanggaran",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/monitoring/verifikasi",
            },
            {
              id: "eventditunda",
              title: "Event Ditunda",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/monitoring/eventditunda"
            },
            {
              id: "hasilUjian",
              title: "Riwayat",
              type: "item",
              icon: <Icon.Circle size={12} />,
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "ACCESSOR", "200", "100"],
              navLink: "/monitoring/hasil",
            },
          ],
        },
        {
          id: "pengaturan",
          title: "Pengaturan",
          icon: <Icon.Grid size={20} />,
          type: "collapse",
          permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
          children: [
            {
              id: "soalMenu",
              title: "Konfigurasi Soal",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/modul/soal",
            },
            {
              id: "topicMenu",
              title: "Konfigurasi Topik",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/modul/topik",
            },
            {
              id: "modulMenu",
              title: "Konfigurasi Modul",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/modul/modul",
            },
            {
              id: "listPengguna",
              title: "Daftar Pengguna",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "100"],
              navLink: "/pengguna",
            }
          ],
        },
        {
          id: "sinkronisasi",
          title: "Sync Data",
          icon: <Icon.Repeat size={20} />,
          type: "item",
          permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI","200", "100"],
          navLink: "/sync/ujian",
        }
      ]
    }else if(getUserLogged().userrole === 200 || getUserLogged().userrole === "200"){
      navigationConfig = [
        {
          id: "ujian",
          title: "Ujian",
          icon: <Icon.BookOpen color={"#1a1c20"} size={20} />,
          type: "collapse",
          children: [
            {
              id: "daftarSertifikat",
              title: "Daftar Sertifikat",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/sertifikat",
            },
            {
              id: "jadwalUjian",
              title: "Jadwal Ujian",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/ujian/sesi",
            },
            {
              id: "pesertaUjian",
              title: "Peserta Ujian",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/pesertaujian",
            }
          ],
        },
        {
          id: "monitoring",
          title: "Monitoring",
          icon: <Icon.Calendar color={"#1a1c20"} size={20} />,
          type: "collapse",
          children: [
            {
              id: "monitoringUjian",
              title: "Monitoring Ujian",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/monitoring/realtime",
            },
            {
              id: "verifikasiPelanggaran",
              title: "Verifikasi Pelanggaran",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/monitoring/verifikasi",
            },
            {
              id: "eventditunda",
              title: "Event Ditunda",
              icon: <Icon.Circle size={12} />,
              type: "item",
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "200", "100"],
              navLink: "/monitoring/eventditunda"
            },
            {
              id: "hasilUjian",
              title: "Riwayat",
              type: "item",
              icon: <Icon.Circle size={12} />,
              permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI", "ACCESSOR", "200", "100"],
              navLink: "/monitoring/hasil",
            },
          ],
        },
        {
          id: "sinkronisasi",
          title: "Sync Data",
          icon: <Icon.Repeat size={20} />,
          type: "item",
          permissions: ["admin", "editor", "SUPER USER", "ADMIN SPPI","200", "100"],
          navLink: "/sync/ujian",
        }
      ]
    }else{
      navigationConfig = []
    }
  }
}

export default navigationConfig;
