import * as firebase from "firebase/app"
import { history } from "../../../history"
import "firebase/auth"
import "firebase/database"
import axios from "axios"
import { config } from "../../../authServices/firebase/firebaseConfig"
import {BASE_API, handleAccesstoken, getAccesstoken, ACCESS_TOKEN} from "../../../configs/config";


// Init firebase if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

let firebaseAuth = firebase.auth()

// const initAuth0 = new auth0.WebAuth(configAuth)


const userList = {

}

export const loginWithJWT = user => {


  return async dispatch => {
    let loggedInUser = {
      username: user.username,
      password: user.password
    }
    let response = await fetch(BASE_API + "/api/backoffice/login", {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(loggedInUser)
    }).then(response => response.json()).then(data => data);
    // console.log("Response");
    // console.log(response);
    if (response.response.message.includes("Berhasil login")) {
      // console.log("Access Token");
      // console.log(response.data.token);
      let accessToken = response.data.token;
      let userData = response.data.user;


      handleAccesstoken(accessToken);
      localStorage.setItem('loggedInUser', JSON.stringify({
        username:userData.username,
        userrole:userData.userRole.userTypeId,
        userRoleName:userData.userRole.userTypeName,
        userid:userData.userId,
        useremail:userData.email
      }));
      localStorage.setItem("sidebarCollapsed", false)

      dispatch({
        type: "LOGIN_WITH_JWT",
        payload: { loggedInUser, loggedInWith: "jwt" }
      })

    //  history.push(process.env.PUBLIC_URL+"");
      console.log(process.env.PUBLIC_URL)
     history.push(process.env.PUBLIC_URL+"/ujian/sesi");


      //window.location.reload();
    } else {
      dispatch({
        type: "CREATE_PARAM",
        routeParam: {"message": "Username / password tidak tepat"}
      })
      // history.push(process.env.PUBLIC_URL+"/auth/login");

    }
  }

}

export const logoutWithJWT = () => {
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    localStorage.clear()
    history.push(process.env.PUBLIC_URL+"/auth/login")
    window.location.reload();
  }
}

export const logoutWithFirebase = user => {
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} })
    history.push(process.env.PUBLIC_URL+"/auth/login")
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
