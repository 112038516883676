import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { Disc, X, Circle } from "react-feather"
import classnames from "classnames"
import {logoLsppi} from "../../../../assets/img/logo/logo-lsppi.png"
import * as Icon from "react-feather"
class SidebarHeader extends Component {
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow
    } = this.props
    return (
      <div className="navbar-header" style={{background:"#f9f9f9"}}>
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/" className="navbar-brand">
              <img style={{width: "auto", height: "24px"}} src={require("../../../../assets/img/logo/logo-lsppi.png")}/>
            </NavLink>
          </li>
           <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle" >
              {collapsed === false ? (
                <Icon.BookOpen
                  onClick={() => {
                    toggleSidebarMenu(true)
                    toggle()
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4 text-light"
                  )}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                <Icon.Book
                  onClick={() => {
                    toggleSidebarMenu(false)
                    toggle()
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4 text-light"
                  )}
                  size={20}
                />
              )}
              <X
                onClick={sidebarVisibility}
                className={classnames(
                  "toggle-icon icon-x d-block d-xl-none font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark"
                  }
                )}
                size={20}
              />
            </div>
          </li>
        </ul>
        {/*<div*/}
        {/*  className={classnames("shadow-bottom", {*/}
        {/*    "d-none": menuShadow === false*/}
        {/*  })}*/}
        {/*/>*/}
      </div>
    )
  }
}

export default SidebarHeader
