let resultState = {
  result: []
  }
  const MyResultReducer = (state = resultState, action ) =>{
    switch(action.type){
      case "GET_RESULT":
        return{
          ...state,
          result: action.result
        }
      case "RECON_RESULT":
        state.result = [];
        return{
          ...state,
          result: action.result
        }
      default:
        return{
          ...state
        }
    }
  
  }
  export default MyResultReducer