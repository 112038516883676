let monitorState = {
    listUjian: []
}

const MyMonitorReducer = (state = monitorState, action ) =>{
switch(action.type){
    case "GET_MONITOR":
    return {
        ...state,
        listUjian: action.data
    }
    default:
    return{
        ...state
    }
}

}
export default MyMonitorReducer
  