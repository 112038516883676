let modulState = {
  modul: []
}
const MyModulReducer = (state = modulState, action ) =>{
  switch(action.type){
    case "GET_MODUL":
      return{
        ...state,
        modul: action.modul
      }
    case "RECON_MODUL":

      state.modul = [];
      return{
        ...state,
        modul: action.modul
      }
    case "PERSIST_MODUL":
      return{
        ...state,
        modul:  action.modul
      }
    case "MODIFY_MODUL":
      return{
        ...state,
        modul:  action.modul
      }
    case "REMOVE_MODUL":
      state.modul = [];
      return{
        ...state,
        modul:  action.modul
      }
    default:
      return{
        ...state
      }
  }

}
export default MyModulReducer
