let userState = {
  user: []
}
const UserReducer = (state = userState, action ) =>{
  switch(action.type){
    case "RECON_USER":
      state.user = [];
      return {
        ...state,
        user: action.user
      }
    case "GET_USER":
      return{
        ...state,
        user:  action.user
      }
    case "PERSIST_USER":
      return{
        ...state,
        user:  action.user
      }
    case "REMOVE_USER":
      return{
        ...state,
        user:  action.user
      }
    default:
      return{
        ...state
      }
  }

}
export default UserReducer
