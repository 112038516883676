let taskState = {
  task: []
}
const MyTaskReducer = (state = taskState, action ) =>{
  switch(action.type){
    case "RECON_TASK":
      state.task = [];
      return {
        ...state,
        task: action.task
      }
    case "GET_TASK":
      return{
        ...state,
        task:  action.task
      }
    case "PERSIST_TASK":
      alert("Kepanggil");
      return{
        ...state,
        task:  action.task
      }
    case "REMOVE_TASK":
      return{
        ...state,
        task:  action.task
      }
    default:
      return{
        ...state
      }
  }

}
export default MyTaskReducer
