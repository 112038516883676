let topicState = {
  topic: []
}
const MyTopicReducer = (state = topicState, action ) =>{
  switch(action.type){
    case "RECON_TOPIC":
      state.topic = [];
      return{
        ...state,
        topic: action.topic
      }
    case "GET_TOPIC":
      return{
        ...state,
        topic: action.topic
      }
    case "PERSIST_TOPIC":
      return{
        ...state,
        topic:  action.topic
      }
    case "MODIFY_TOPIC":
      return{
        ...state,
        topic:  action.topic
      }
    case "REMOVE_TOPIC":
      state.topic = [];
      return{
        ...state,
        topic:  action.topic
      }
    default:
      return{
        ...state
      }
  }

}
export default MyTopicReducer
