import { combineReducers } from "redux"
import calenderReducer from "./calendar/"
import emailReducer from "./email/"
import chatReducer from "./chat/"
import todoReducer from "./todo/"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import dataList from "./data-list/"
import routeParam from "./routeParam/"
import ujian from "./myUjian";
import modul from "./myModul";
import topic from "./myTopic";
import task from "./myTask";
import monitor from "./monitor";
import result from "./myHasil";
import user from "./user";
import monitorUjian from "./myMonitor";
import jadwalUjian from "./myJadwalUjian";


const rootReducer = combineReducers({
  calendar: calenderReducer,
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  routeParam: routeParam,
  ujian: ujian,
  modul: modul,
  topic: topic,
  task: task,
  monitor: monitor,
  hasilUjian:result,
  user: user,
  monitorUjian: monitorUjian,
  jadwalUjian: jadwalUjian
})

export default rootReducer
