import moment from "moment";
export const LOGIN_API="https://api.sppi.co.id/";
export const CHECK_JWT_API="https://45.126.132.161:31088/valid";
export const CHECK_JWT_EXAM_API="https://45.126.132.161:31088/validexam";
export const CMS_AUTH="https://localhost/api/user";
export const IDVERIFY_AUTH = "https://api-idver.rapindo.co.id/api/v1/request-access";
export const IDVERIFY_VERIFY="https://api-idver.rapindo.co.id/api/v1/asli-ri/VERIFY_PROF_ID";
export let ACCESS_TOKEN = "";
// export const BASE_API="https://ujol.sppi.id/api";
export const BASE_API="https://api-dev-ujol.basys.co.id/be-master-api";
// export const BASE_API= process.env.REACT_APP_AUTH0_DOMAIN;
// export const BASE_API="http://localhost:1202";
// export const BASE_API = "https://devujol.sppi.id/api";
const HOOK_CHANGE = "jenkins";

export const FACEAPI_TOKEN="fe1bf00a47632b59a7bb392866f276a9";
export const FACEAPI_PROD_TOKEN="02a3f2dccff78930c55684eb59307ddd";
export let IS_CONNECTED = true;
export const IP_CHECK = "116.204.128.102";
export const LINK_DETAIL = "/back-office";

export const handleValue = (value) => {
  if(value.toString().length === 1)
    return "0"+value;
  return value;
}

// export const handleAccesstoken = (accessToken) => {
//   localStorage.setItem('accessToken', "eyJpc2NibiI6dHJ1ZSwiYWxnIjoiUlMyNTYifQ.eyJ0b2tlbiI6ImV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUpsYldGcGJDSTZJaUlzSW1WNGNDSTZNVFl5TXprME1EY3dNeXdpWlhod2FYSmxjMTl2YmlJNklqSXdNakV0TURZdE1UZFVNakU2TXpnNk1UZ3JNRGM2TURBaUxDSnBZWFFpT2pFMk1qTTVNemN3T1Rnc0ltbGtJam93TENKcGMzTWlPaUpCVTFoRFJTSXNJbTVoYldVaU9pSlZhbTlzSUZOUVVFa2lMQ0p1WW1ZaU9qRTJNak01TXpjd09UZ3NJbkpsWm5KbGMyaGZkRzlyWlc0aU9pSnBUWGxMUWtVaUxDSnliMnhsSWpvaVVHVnpaWEowWVNJc0luTjFZaUk2SWtwM2RDQkZibU55ZVhCMFpXUWdRVlZVU0NKOS5iRWduNzNCN3JQRlVHaHZ0UmxWYzVja1pabEFYYTJkQlV5Mmx6VnRaYnpJIiwiZXhwIjoxNjI0MDIzNDk4fQ.XAp0fxJWge3xHAlT18AmZRrHVTgn1tJFGGjxTThjEJcnu_Whwf3-r2qo1J-2a0iuHQ7--B6WBrUmC-TSjmSmFTlurbpkJl8DkKOwPldFFYXoAHpyh4JyXMAFBFHkxl4wH97Bt6Tw45GpqH0qkJ8Cib_l-5rnMq2GWeAqOkcQyWvM8lGqhbcNzoCOUSSUnV_8w1RoVMVcC3bs3VR31_tdif428s28G7nshWOucdZRFsntlQynEc0M9iyD3hoRjDwJZfFInuhQIHcsc_hF7m0DNooUjDpOcZk-hXedRSwwuI9jY79y3zYi2dQJQpy75HlhHQlQCHy_OJr-1wx-Ud_PMw");
// }

export const handleAccesstoken = (accessToken) => {
  localStorage.setItem('accessToken', accessToken);
}

export const getAccesstoken = () => {
  ACCESS_TOKEN = localStorage.getItem('accessToken')
  return ACCESS_TOKEN;
}

export const getUserLogged = () => {
  let userLoggedIn = JSON.parse(localStorage.getItem('loggedInUser'));
  return userLoggedIn;
}

export const getDate = (a) => {
  switch(a){
    case 1: return "Senin";
    case 2: return "Selasa";
    case 3: return "Rabu";
    case 4: return "Kamis";
    case 5: return "Jumat";
    case 6: return "Sabtu";
    case 0: return "Minggu";
  }
}

export const handleTime = (num)=>{
  if(num.toString().length == 1){
    return "0"+num;
  }
  return num;
}
export const convertTime = (date) => {
  let _date = "Jam "+ moment(date).format("HH:mm").toString() + " WIB";
  return _date;
}
export const convertDate = (date) => {
  let _base = moment(date);
  let hari = getDate(_base.toDate().getDay());
  let _date = hari+", "+moment(date).format("DD-MMM-YYYY").toString();

  return _date;
}

export const addDate = (date, length) => {
  length = parseInt(length);
  let _base = moment(date, "YYYY-MM-DD HH:mm").add(length, 'minute');
  let hari = getDate(_base.toDate().getDay());
  let _date = hari+" "+_base.format("YYYY-MM-DD HH:mm").toString();
  return _date;
}

export const gapDate = (date) => {
  console.log("Before Date");
  console.log(date);
  console.log("After Date");
  console.log(new Date());
  let _date = new Date(date).getTime() - new Date().getTime();
  let _mDay = Math.abs(parseInt((_date)/(24*3600*1000)));
  let _mHours = Math.abs(parseInt((_date/3600)%24));
  let _mMinute = Math.abs(parseInt((_date/60)%60));
  let _mSecond = 0;
  return {
    day: _mDay,
    hour: _mHours,
    minute: _mMinute,
    second: _mSecond
  }
}

export const getTimer = (length)=>{
  let _mhour = Math.floor(length/60);
  let _mminute = Math.abs(length%60);
  let _msecond = 0;
  return {
    day: 0,
    hour: _mhour,
    minute: _mminute,
    second: _msecond
  }
}
export const access = {
  accessToken: ""
}

export const MAX_EUCLID = 0.8;


export const requestGet = {

    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization" : "Bearer " + localStorage.getItem('accessToken')
    },
    body: JSON.stringify()

}


export const requestPost = (postdata) => {

   return {

        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization" : "Bearer " + localStorage.getItem('accessToken')
        },
        body: JSON.stringify(postdata)

    }
}


export const getQueryParamsByName =  (paramName) => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get(paramName)
}
