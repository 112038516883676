const monitorState = {
  monitor: []
}

const MonitorReducer = (state = monitorState, action ) =>{
  switch(action.type){
    case "CREATE_MONITOR":
      return{
        ...state,
        monitor: action.monitor
      }
    case "RECON_MONITOR":
      return{
        ...state,
        monitor: action.monitor
      }
    default:
      return{
        ...state
      }
  }
}

export default MonitorReducer

