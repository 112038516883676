let ujianState = {
    ujian: []
  }
  const UjianReducer = (state = ujianState, action ) =>{
    switch(action.type){
      case "GET_UJIAN":
        return{
          ...state,
          ujian: action.ujian
        }
      case "RECON_UJIAN":
  
        state.ujian = [];
        return{
          ...state,
          ujian: action.ujian
        }
      case "PERSIST_UJIAN":
        return{
          ...state,
          ujian:  action.ujian
        }
      case "MODIFY_UJIAN":
        return{
          ...state,
          ujian:  action.ujian
        }
      case "REMOVE_UJIAN":
        state.ujian = [];
        return{
          ...state,
          ujian:  action.ujian
        }
      default:
        return{
          ...state
        }
    }
  
  }
  export default UjianReducer